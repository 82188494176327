import React, { Fragment, useEffect, useState } from 'react';

import { getFirestore, onSnapshot, doc } from 'firebase/firestore';
import { Col, Row } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { app } from 'utils/constants';
import 'styles/finance.scss';
import 'react-tabs/style/react-tabs.css';

const Documents = () => {
	const db = getFirestore(app);
	const [docs, setDocs] = useState(null);

	const getFirebaseDocs = () => {
		onSnapshot(doc(db, 'bptdocumentation', 'main-bpt'), (doc) => {
			if (doc.exists) {
				setDocs(doc.data());
			} else {
				setDocs(null);
			}
		});
	};

	useEffect(() => {
		getFirebaseDocs();
	}, []);

	return (
		<Fragment>
			<Row className="g-0">
				<Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
					<Tabs>
						<TabList>
							<Tab xs={12} sm={12} md={6} lg={6} xl={6}>
								{'Información Financiera'}{' '}
							</Tab>
							<Tab xs={12} sm={12} md={6} lg={6} xl={6}>
								{'Gobierno Corporativo'}{' '}
							</Tab>
						</TabList>
						<TabPanel>
							<Row className="g-0">
								<Col
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
									style={{ textAlign: 'left' }}>
									<h2 className="mb-3 mt-3">{'BANCO PAGATODO, S.A.'} </h2>
								</Col>
								<Col
									xs={12}
									sm={12}
									md={12}
									lg={6}
									xl={6}
									style={{ textAlign: 'left' }}>
									<h2 className="mb-3 mt-3">{'Información Trimestral'} </h2>
									<ListGroup variant="flush">
										{docs ? (
											<>
												{docs.financtialInfo.quarterlyInfo.length > 0 ? (
													<>
														{docs.financtialInfo.quarterlyInfo.map(
															(item, index) => (
																<ListGroup.Item
																	key={index.toString()}
																	style={{ color: '#fafafa!important' }}>
																	<a
																		href={item.docUri}
																		target="_blank"
																		rel="noreferrer"
																		className="documents">
																		{item.title}
																	</a>
																</ListGroup.Item>
															)
														)}
													</>
												) : (
													<p>Sin Resultados</p>
												)}
											</>
										) : (
											<p>Sin Resultados</p>
										)}
									</ListGroup>
									<h2 className="mb-3 mt-3">{'Información Anual'} </h2>
									<ListGroup variant="flush">
										{docs ? (
											<>
												{docs.financtialInfo.annualInfo.length > 0 ? (
													<>
														{docs.financtialInfo.annualInfo.map(
															(item, index) => (
																<ListGroup.Item
																	key={index.toString()}
																	style={{ color: '#fafafa!important' }}>
																	<a
																		href={item.docUri}
																		target="_blank"
																		rel="noreferrer"
																		className="documents">
																		{item.title}
																	</a>
																</ListGroup.Item>
															)
														)}
													</>
												) : (
													<p>Sin Resultados</p>
												)}
											</>
										) : (
											<p>Sin Resultados</p>
										)}
									</ListGroup>
								</Col>
								<Col
									xs={12}
									sm={12}
									md={12}
									lg={6}
									xl={6}
									style={{ textAlign: 'left' }}>
									<h2 className="mb-3 mt-3">{'Razón de Apalancamiento'} </h2>
									<ListGroup variant="flush">
										{docs ? (
											<>
												{docs.financtialInfo.leverageInfo.length > 0 ? (
													<>
														{docs.financtialInfo.leverageInfo.map(
															(item, index) => (
																<ListGroup.Item
																	key={index.toString()}
																	style={{ color: '#fafafa!important' }}>
																	<a
																		href={item.docUri}
																		target="_blank"
																		rel="noreferrer"
																		className="documents">
																		{item.title}
																	</a>
																</ListGroup.Item>
															)
														)}
													</>
												) : (
													<p>Sin Resultados</p>
												)}
											</>
										) : (
											<p>Sin Resultados</p>
										)}
									</ListGroup>
								</Col>
							</Row>
						</TabPanel>
						<TabPanel>
							<Row className="g-0">
								<Col
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
									style={{ textAlign: 'left' }}>
									<h2 className="mb-3 mt-3">{'BANCO PAGATODO, S.A.'} </h2>
								</Col>
								<Col
									xs={12}
									sm={12}
									md={12}
									lg={6}
									xl={6}
									style={{ textAlign: 'left' }}>
									<h2 className="mb-3 mt-3">{'Información Trimestral'} </h2>
									<ListGroup variant="flush">
										{docs ? (
											<>
												{docs.corporateGovernance.quarterlyInfo.length > 0 ? (
													<>
														{docs.corporateGovernance.quarterlyInfo.map(
															(item, index) => (
																<ListGroup.Item
																	key={index.toString()}
																	style={{ color: '#fafafa!important' }}>
																	<a
																		href={item.docUri}
																		target="_blank"
																		rel="noreferrer"
																		className="documents">
																		{item.title}
																	</a>
																</ListGroup.Item>
															)
														)}
													</>
												) : (
													<p>Sin Resultados</p>
												)}
											</>
										) : (
											<p>Sin Resultados</p>
										)}
									</ListGroup>
									<h2 className="mb-3 mt-3">{'Asambleas'}</h2>
									<ListGroup variant="flush">
										{docs ? (
											<>
												{docs.corporateGovernance.assemblies.length > 0 ? (
													<>
														{docs.corporateGovernance.assemblies.map(
															(item, index) => (
																<ListGroup.Item
																	key={index.toString()}
																	style={{ color: '#fafafa!important' }}>
																	<a
																		href={item.docUri}
																		target="_blank"
																		rel="noreferrer"
																		className="documents">
																		{item.title}
																	</a>
																</ListGroup.Item>
															)
														)}
													</>
												) : (
													<p>Sin Resultados</p>
												)}
											</>
										) : (
											<p>Sin Resultados</p>
										)}
									</ListGroup>
									<h2 className="mb-3 mt-3"> {'Estatutos Sociales'} </h2>
									<ListGroup variant="flush">
										{docs ? (
											<>
												{docs.corporateGovernance.socialStatutes.length > 0 ? (
													<>
														{docs.corporateGovernance.socialStatutes.map(
															(item, index) => (
																<ListGroup.Item
																	key={index.toString()}
																	style={{ color: '#fafafa!important' }}>
																	<a
																		href={item.docUri}
																		target="_blank"
																		rel="noreferrer"
																		className="documents">
																		{item.title}
																	</a>
																</ListGroup.Item>
															)
														)}
													</>
												) : (
													<p>Sin Resultados</p>
												)}
											</>
										) : (
											<p>Sin Resultados</p>
										)}
									</ListGroup>
									<h2 className="mb-3 mt-3">
										{' '}
										{'Informe de la Administración'}{' '}
									</h2>
									<ListGroup variant="flush">
										{docs ? (
											<>
												{docs.corporateGovernance.managementReport.length >
												0 ? (
													<>
														{docs.corporateGovernance.managementReport.map(
															(item, index) => (
																<ListGroup.Item
																	key={index.toString()}
																	style={{ color: '#fafafa!important' }}>
																	<a
																		href={item.docUri}
																		target="_blank"
																		rel="noreferrer"
																		className="documents">
																		{item.title}
																	</a>
																</ListGroup.Item>
															)
														)}
													</>
												) : (
													<p>Sin Resultados</p>
												)}
											</>
										) : (
											<p>Sin Resultados</p>
										)}
									</ListGroup>
								</Col>
								<Col
									xs={12}
									sm={12}
									md={12}
									lg={6}
									xl={6}
									style={{ textAlign: 'left' }}>
									<h2 className="mb-3 mt-3"> {'Revelación de Información'} </h2>
									<ListGroup variant="flush">
										{docs ? (
											<>
												{docs.corporateGovernance.disclosureInformation.length >
												0 ? (
													<>
														{docs.corporateGovernance.disclosureInformation.map(
															(item, index) => {
																// Verificar si el título contiene "2023" al final
																const contiene2023 =
																	/\b(202[3-9]|20[3-9]\d)\b/.test(item.title);

																return (
																	<Fragment key={index.toString()}>
																		{contiene2023 && (
																			<ListGroup.Item
																				style={{ color: '#fafafa!important' }}>
																				<a
																					href={item.docUri}
																					target="_blank"
																					rel="noreferrer"
																					className="documents">
																					{item.title}
																				</a>
																			</ListGroup.Item>
																		)}
																	</Fragment>
																);
															}
														)}
													</>
												) : (
													<p>Sin Resultados</p>
												)}
											</>
										) : (
											<p>Sin Resultados</p>
										)}
									</ListGroup>
								</Col>
							</Row>
						</TabPanel>
					</Tabs>
				</Col>
			</Row>
		</Fragment>
	);
};
export default Documents;
